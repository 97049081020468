import { SchemeArea } from '@keaze/web/common/interfaces';
import { useState } from 'react';

import { TabData } from '@keaze/web/components';
import { SchemeAreasTabsValue } from './schemeAreas.types';

type OnChangeTabs = (value: SchemeAreasTabsValue) => void;

type UseSchemeAreasExpected = {
  tabValue: SchemeAreasTabsValue;
  tabData: SchemeArea;
  tabsData: TabData<SchemeAreasTabsValue>[];
  onChangeTabs: OnChangeTabs;
};

type UseSchemeAreas = (data: SchemeArea[]) => UseSchemeAreasExpected;

export const useSchemeAreas: UseSchemeAreas = (data) => {
  const [tabValue, setTabValue] = useState(
    SchemeAreasTabsValue.SharedOwnership
  );

  const tabsData: TabData<SchemeAreasTabsValue>[] = data.map(
    ({ name, slug }) => ({
      label: 'Key Locations',
      value: slug as SchemeAreasTabsValue,
    })
  );

  const tabData = data.find(({ slug }) => slug === tabValue) as SchemeArea;

  const handleChangeTabs: OnChangeTabs = (value) => setTabValue(value);

  return {
    tabValue,
    tabData,
    tabsData,
    onChangeTabs: handleChangeTabs,
  };
};
