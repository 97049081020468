import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem, { ListItemTypeMap } from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import Masonry from '@mui/lab/Masonry';
import MasonryItem from '@mui/lab/MasonryItem';

import { Tabs as CPTabs } from '@keaze/web/components/tabs';

export const SchemeAreas = styled(Box)`
  ${({ theme: { palette, breakpoints } }) => `
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 0 20px 0;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -50%;
      width: calc(100vw + 50%);
      height: 100%;
      background-color: ${palette.common.white};
      z-index: -1;
    }

    ${breakpoints.up('md')} {
      padding: 50px 0 0;
    }
  `}
`;

export const Regions = styled(Masonry)`
  ${({ theme: { breakpoints } }) => `
    ${breakpoints.down('xs')} {
      grid-auto-rows: unset;
    }
  `}
`;

export const Region = styled(MasonryItem)`
  ${({ theme: { breakpoints } }) => `
    ${breakpoints.down('xs')} {
      grid-row-end: auto;
    }
  `}
`;

export const RegionTitle = styled(Typography)`
  ${({ theme: { breakpoints } }) => `
    margin-bottom: 10px;

    ${breakpoints.up('md')} {
      margin-bottom: 20px;
    }
  `}
`;

export const Tabs: any = styled(CPTabs)`
  ${({ theme: { breakpoints } }) => `
    width: auto;
    margin-bottom: 30px;
    align-self: center;

    .MuiTabs-flexContainer {
      width: 100%;
    }

    .MuiTab-root {
      min-width: 50%;
      flex: 1 1 auto;
    }

    ${breakpoints.up('md')} {
      margin-bottom: 50px;

      .MuiTabs-flexContainer {
        width: auto;
        justify-content: center;
      }

      .MuiTab-root {
        min-width: auto;
        flex: 0 0 auto;
      }
    }
  `}
`;

export const Area: OverridableComponent<
  ListItemTypeMap<{ button?: false }, 'li'>
> = styled(ListItem)`
  ${({
    theme: {
      palette,
      colors,
      typography: { body2 },
      transitions: { easing, duration },
    },
  }) => `
    padding: 5px 0 5px;
    align-items: flex-start;

    a {
      font-weight: 500;
      font-size: ${body2.fontSize};
      line-height: ${body2.lineHeight};
      color: ${colors.blues.blues1};
      transition: ${duration.shortest}ms ${easing.easeInOut};

      &:hover, &:focus {
        color: ${palette.action.hover};
      }
    }
  `}
`;

export const Areas = styled(List)`
  ${({ theme: { breakpoints } }) => `
    display: flex;
    flex-wrap: wrap;

    ${Area} {
      flex: 1 1 50%;
    }

    ${breakpoints.up('sm')} {
      flex-direction: column;

      ${Area} {
        flex: 1 1 100%;
      }
    }
  `}
`;
