import { FC, memo } from 'react';
import NextLink from 'next/link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { UrlObject } from 'url';

import { QueryParams } from '@keaze/web/common/enums';
import { TabPanel } from '@keaze/web/components/tabs';
import {
  SchemeAreaRegion,
  SchemeAreaRegionArea,
} from '@keaze/web/common/interfaces';
import { useSchemeAreas } from './schemeAreas.hook';
import { SchemeAreasProps, SchemeAreasTabsValue } from './schemeAreas.types';
import {
  SchemeAreas as UISchemeAreas,
  Regions,
  Region,
  RegionTitle,
  Tabs,
  Areas,
  Area,
} from './schemeAreas.styles';

type RenderRegion = (region: SchemeAreaRegion, scheme: string) => JSX.Element;

type RenderAreas = (
  areas: SchemeAreaRegionArea[],
  scheme: string
) => JSX.Element;

type RenderArea = (area: SchemeAreaRegionArea, scheme: string) => JSX.Element;

type RenderRegions = (
  regions: SchemeAreaRegion[],
  scheme: string
) => JSX.Element;

const renderArea: RenderArea = ({ name, slug }, scheme) => {
  const href: UrlObject = {
    pathname: `/${scheme}/${encodeURIComponent(slug)}`,
    // query: {
    //   [QueryParams.Location]: name,
    // },
  };

  return (
    <Area key={name} disableGutters>
      <NextLink href={href} prefetch={false} passHref>
        <a>{name}</a>
      </NextLink>
    </Area>
  );
};

const renderAreas: RenderAreas = (areas, scheme) => (
  <Areas disablePadding>{areas.map((area) => renderArea(area, scheme))}</Areas>
);

const renderRegion: RenderRegion = ({ name, areas }, scheme) => (
  <Box>
    <RegionTitle variant="h3">{name}</RegionTitle>
    {renderAreas(areas, scheme)}
  </Box>
);

const renderRegions: RenderRegions = (regions, scheme) => (
  <Grid container>
    <Regions
      columns={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
      spacing={{ xs: 2, sm: 2, md: 4, lg: 5, xl: 5 }}
    >
      {regions.map((region, index) => (
        <Region key={index}>{renderRegion(region, scheme)}</Region>
      ))}
    </Regions>
  </Grid>
);

export const SchemeAreas: FC<SchemeAreasProps> = memo(({ data }) => {
  const {
    tabValue,
    tabData: { regions, slug: scheme },
    tabsData,
    onChangeTabs,
  } = useSchemeAreas(data);

  return (
    <UISchemeAreas>
      <Tabs
        value={tabValue}
        data={tabsData}
        ariaLabel="scheme areas tabs"
        onChange={onChangeTabs}
      >
        <TabPanel value={tabValue} index={SchemeAreasTabsValue.SharedOwnership}>
          {renderRegions(regions, scheme)}
        </TabPanel>
        <TabPanel value={tabValue} index={SchemeAreasTabsValue.HelpToBuy}>
          {renderRegions(regions, scheme)}
        </TabPanel>
      </Tabs>
    </UISchemeAreas>
  );
});
