import { SchemeArea } from '@keaze/web/common/interfaces';

export type SchemeAreasProps = {
  data: SchemeArea[];
};

export enum SchemeAreasTabsValue {
  SharedOwnership = 'shared-ownership',
  HelpToBuy = 'help-to-buy',
}
